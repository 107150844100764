import Vue from 'vue'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import './stylus/main.styl'

Vue.use( Vuetify, {
	theme: {
		// primary: '#1976D2', // the blue, we're chaging it to Pantone 1505 C
		primary: '#4B95FF',
		secondary: '#424242',
		accent: '#82B1FF',
		error: '#FF5252',
		info: '#2196F3',
		success: '#4CAF50',
		warning: '#FFC107'
	}
} )


/** vue-scroll-to **/

import VueScrollTo from 'vue-scrollto'

Vue.use( VueScrollTo, {
	container: 'body',
	duration: 500,
	easing: 'ease',
	offset: -48,
	cancelable: true,
	onStart: false,
	onDone: false,
	onCancel: false,
	x: false,
	y: true
} )

/** vue-lazyload **/

import VueLazyload from 'vue-lazyload'

Vue.use( VueLazyload, {
	attempt: 5
} )

/** v-click-outside **/

import vClickOutside from 'v-click-outside'

Vue.use( vClickOutside )

/** my components */
// toolbar
/*
import DonateForm from './components/DonateForm.vue'
import BelowNavSearchBar from './components/BelowNavSearchBar.vue'
import InNavSearchBar from './components/InNavSearchBar.vue'
import SearchToggle from './components/SearchToggle.vue'
import NavbarLogo from './components/NavbarLogo.vue'
import SearchPageSearchWidget from './components/SearchPageSearchWidget.vue'
import LanguageSwitcher from './components/LanguageSwitcher.vue'
import Toolbar from './components/Toolbar.vue'
import MainMenu from './components/MainMenu.vue'
import MainMenuSubMenu from './components/MainMenuSubMenu.vue'
import HeaderButtons from './components/HeaderButtons.vue'
import SocialIcons from './components/SocialIcons.vue'
import MailingListSignup from './components/MailingListSignup.vue'
import Hero from './components/Hero.vue'
*/
/** lazy loading components instead **/
const DonateForm = () => import( './components/DonateForm.vue')
const BelowNavSearchBar = () => import( './components/BelowNavSearchBar.vue')
const InNavSearchBar = () => import( './components/InNavSearchBar.vue')
const SearchToggle = () => import( './components/SearchToggle.vue')
const NavbarLogo = () => import( './components/NavbarLogo.vue')
const SearchPageSearchWidget = () => import( './components/SearchPageSearchWidget.vue')
const LanguageSwitcher = () => import( './components/LanguageSwitcher.vue')
const Toolbar = () => import( './components/Toolbar.vue')
const MainMenu = () => import( './components/MainMenu.vue')
const MainMenuSubMenu = () => import( './components/MainMenuSubMenu.vue')
const HeaderButtons = () => import( './components/HeaderButtons.vue')
const SocialIcons = () => import( './components/SocialIcons.vue')
const MailingListSignup = () => import( './components/MailingListSignup.vue')
const Hero = () => import( './components/Hero.vue')

Vue.component( 'DonateForm', DonateForm )
Vue.component( 'BelowNavSearchBar', BelowNavSearchBar )
Vue.component( 'InNavSearchBar', InNavSearchBar )
Vue.component( 'SearchToggle', SearchToggle )
Vue.component( 'NavbarLogo', NavbarLogo )
Vue.component( 'SearchPageSearchWidget', SearchPageSearchWidget )
Vue.component( 'LanguageSwitcher', LanguageSwitcher )
Vue.component( 'Toolbar', Toolbar )
Vue.component( 'MainMenu', MainMenu )
Vue.component( 'MainMenuSubMenu', MainMenuSubMenu )
Vue.component( 'HeaderButtons', HeaderButtons )
Vue.component( 'SocialIcons', SocialIcons )
Vue.component( 'MailingListSignup', MailingListSignup )
Vue.component( 'Hero', Hero )


/** global */
// import './assets/font-awesome.css'
import './styles/fonts.scss'
import './styles/global.scss'
import './styles/animate.scss'
// import './assets/fontawesome/scss/fontawesome.scss'
/** my plugins **/
import EventBus from './plugins/event-bus'

Vue.use( EventBus )

import store from './store'

let app = new Vue( {
	el: '#page',
	store
} )