import { Actions, Mutations } from '../modules/constants'

export const actions = {
  // [Actions.Login] ({commit, state, dispatch}, payload) {
  //   this.$restAPI.loginUser().then((response) => {
  //     commit(Mutations.SetUserDetails, response)
  //   })
  // },
}
export default actions
