import { Mutations } from '../modules/constants'
import Vue from 'vue'

export const mutations = {
	[ Mutations.searchBarVisible ]( state, payload )
	{
		Vue.set( state.ui, 'searchShown', payload )
	}
}

export default mutations
