const UIEvents = {
	TOGGLE_NAV_BAR: 'TOGGLE_NAV_BAR',
	TOGGLE_CONTACT_DIALOG: 'TOGGLE_CONTACT_DIALOG',
	TOGGLE_SEARCH: 'TOGGLE_SEARCH'
}

const Actions   = {}
const Getters   = {}

const Mutations = {
	searchBarVisible: 'searchBarVisible'
}

export { UIEvents, Actions, Getters, Mutations }