import _ from 'lodash'

export const getters = {
  /**
   * User Stuff
   */
  // isValidBillingInfo (state, getters) {
  //   let validity = true
  //   if (Object.keys(state.contactInfo).length === 0) {
  //     validity = false
  //   }
	//
  //   if (validity && !state.contactInfo.hasOwnProperty('billing')) {
  //     validity = false
  //   }
	//
  //   if (validity && Object.keys(state.contactInfo.billing).length !== 9) {
  //     validity = false
  //   }
	//
  //   if (validity) {
  //     Object.keys(state.contactInfo.billing).forEach((key) => {
  //       if (state.contactInfo.billing[key] === '') {
  //         console.log(key + 'Is blank!')
  //         validity = false
  //       }
  //     })
  //   }
	//
  //   return validity
  // },
}

export default getters